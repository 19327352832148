body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Couldn't figure out how to style this through the JS API */
.Polaris-Frame-Loading__Level {
  background-color: hsla(229, 51%, 53%, 1);
}

.link-learn-more {
  display: flex;
  flex-direction: row;
  color: #2c6ecb;
  white-space: nowrap;
}

.link-learn-more > span {
  margin: 0 0 0 3px;
  fill: #2c6ecb;
  white-space: nowrap;
}

.user-menu-skeleton {
  opacity: 0.5;
}

.user-menu-skeleton .Polaris-SkeletonDisplayText--sizeSmall {
  width: 128px;
  position: relative;
  right: 16px;
}

.object-fit-placeholder-graphic {
  margin: 8px 0;
  width: 140px;
}

.object-fit-placeholder-graphic div {
  height: 0;
  padding-bottom: 133.33%;
  background: #ccc;
  position: relative;
}

.object-fit-placeholder-graphic img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.settings-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 24px;
  padding: 8px;
}

@media (min-width: 768px) {
  .settings-grid {
    grid-template-columns: repeat(3, 1fr);
    padding: 16px;
  }
}

.link-tile a {
  display: block;
  padding: 8px;
}

.link-tile a:hover,
.link-tile a:focus {
  background: #f9fafb;
}

.link-tile a:hover .link-tile-icon-wrap,
.link-tile a:focus .link-tile-icon-wrap {
  background: #dfe3e8;
}

.link-tile a:hover .link-tile-title,
.link-tile a:focus .link-tile-title {
  color: #084e8a;
}

.link-tile a:focus {
  outline: none !important;
}

.link-tile-icon-wrap {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background: #f4f6f8;
  color: #919eab;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 12px;
  margin-right: 16px;
}

.link-tile-icon-wrap svg {
  fill: currentColor;
}

.link-tile-description {
  margin-top: 2px;
}

.link-tile-inner {
  display: flex;
}

/* -------------------- Polaris Overrides -------------------- */

/* Button overrides */

.RepeatColors .Polaris-Button--primary {
  --p-button-color: #2c6ecb;
  --p-action-primary-hovered: #1f5199;
  --p-action-primary-pressed: #103262;
}

/* Navigation Overrides */

.RepeatColors .Polaris-Navigation--subNavigationActive .Polaris-Icon__Svg {
  fill: #4d2f8e;
}
.RepeatColors
  .Polaris-Navigation--subNavigationActive:hover
  .Polaris-Icon__Svg {
  fill: #4d2f8e;
}
.RepeatColors .Polaris-Icon:hover {
  fill: #4d2f8e;
}
.RepeatColors .Polaris-Navigation__Item--selected {
  color: #4d2f8e;
  --p-action-primary: #4d2f8e;
}
.RepeatColors .Polaris-Navigation__Item--selected:hover {
  color: #4d2f8e;
  --p-action-primary: #4d2f8e;
}
.RepeatColors .Polaris-Navigation__Item:active {
  color: #4d2f8e;
  --p-action-primary: #4d2f8e;
}

/* Tab Override */

.RepeatColors .Polaris-Tabs__Tab--selected {
  --p-action-primary: #4d2f8e;
}

.RepeatColors .Polaris-Tabs__TabContainer {
  --p-surface-primary-selected-pressed: #8172a1;
}

.RepeatColors .Polaris-TopBar {
  background-color: #171717;
}

/* User Tab */

.RepeatColors .Polaris-TopBar-UserMenu__Detail {
  color: white;
}

.RepeatColors .Polaris-TopBar-Menu__Activator:hover {
  background-color: #444444;
}

.RepeatColors .Polaris-TopBar-Menu__Activator[aria-expanded="true"] {
  background-color: #444444;
}

.RepeatColors .Polaris-TopBar-UserMenu__Name {
  color: white;
}

/* Loading Bar */

.Polaris-Frame-Loading__Level {
  background-color: #8172a1;
}

/* Help Icon */
.HelpButton .Polaris-Icon__Svg {
  fill: white;
}

.image-preview {
  border: 1px solid #c9cccf;
  border-radius: 4px;
  background-size: contain;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  height: 80%;
  width: 80%;
}

.analytics-export-container {
  display: flex;
  justify-content: flex-end;
  margin: 16px;
}

/* -------------------- Bug on IndexTable display in Chrome -------------------- */
/* https://stackoverflow.com/a/53559396 */
.ProductTableCustomizations table.Polaris-IndexTable__Table {
  border-collapse: separate;
  border-spacing: 0px;
}

.under-construction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.under-construction img {
  width: 100%;
  max-width: 50px;
}

.under-construction .title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 16px;
}
